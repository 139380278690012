import PropTypes from 'prop-types';
import NextHead from 'next/head';

const Head = ({ title, children }) => {
  let displayTitle = `${title} - Arkestro`;

  if (!title) displayTitle = 'Arkestro';
  // Append environment name when it's not the true Production application
  // e.g. Development, Staging, Sandbox, Demo, etc.
  if (process.env.NEXT_PUBLIC_ARKESTRO_ENV && process.env.NEXT_PUBLIC_ARKESTRO_ENV !== 'prod') {
    displayTitle = `${displayTitle} - [${process.env.NEXT_PUBLIC_ARKESTRO_ENV.toUpperCase()}]`;
  }

  return (
    <NextHead>
      <title>{displayTitle}</title>
      {children}
    </NextHead>
  );
};

Head.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
};

Head.defaultProps = {
  title: undefined,
  children: null
};

export default Head;
