import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { attachTrackingAnalytics } from '@services/SegmentService';
import { VENDOR_OVERVIEW_STEP_VIEWED } from 'utils/analytics_constants';
import Carousel from 'components/shared/Carousel/Carousel';
import steps from './steps';

const WelcomeCarousel = ({ onChange }) => {
  useEffect(() => attachTrackingAnalytics(VENDOR_OVERVIEW_STEP_VIEWED, { 'Step Number': 1 }), []);

  return (
    <Carousel onChange={onChange} steps={steps} />
  );
};

WelcomeCarousel.propTypes = {
  onChange: PropTypes.func
};

WelcomeCarousel.defaultProps = {
  onChange: undefined
};

export default WelcomeCarousel;
