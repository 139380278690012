import PropTypes from 'prop-types';
import { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import Footer from 'components/on_board/Footer/Footer';
// import OrDivider from '../../../../shared/OrDivider';
import { Button, Col, Row } from '@arkestro/arkestro-design-system';
// import Search from '../.././../../common/Search/Search';
// import { getAllVendorOrgsList } from '../../../../../api/VendorDBApi';
// import { dataSourceOptions } from './utils';
// import { attachTrackingAnalytics } from'@services/SegmentService';
// import { VENDOR_DOMAIN_MATCH_FAILED_VIEWED } from '../../../../../utils/analytics_constants';
// import { UserContext } from '@providers/UserProvider/UserProvider';

// Commented code can be uncommented when we allow searching of supplier orgs

// eslint-disable-next-line quotes
const TITLE = "Next, create a profile for your organization";
const SUBTITLE = `Provide some information about the company or organization you work \n
  for to finish setting up your Arkestro profile`;
// const SUBTITLE = `Try searching for your organization below or, if this is your organization's first \n
//   time using Arkestro, choose "Create a New Organization"`;
// const SEARCH_PLACEHOLDER = 'Search by organization name or organization website';

// non-case sensitive filtering on orgname
// const orgFilterOption = (inputValue, option) => (
//   option.props.orgname.toUpperCase().indexOf(inputValue.trim().toUpperCase()) !== -1
// );
// const reducedVendorOrgList = list => list.map(o => ({ domain: o.domain, text: o.name, value: o.id }));

const FindOrCreateOrganization = ({ setOrgStep }) => {
  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation('common');
  // const currentUser = useContext(UserContext);
  // const [vendorOrgList, setVendorOrgList] = useState([]);
  // const [dataSource, setDataSource] = useState([]);

  // useEffect(() => {
  //   attachTrackingAnalytics(VENDOR_DOMAIN_MATCH_FAILED_VIEWED);

  //   getAllVendorOrgsList()
  //     .then(response => {
  //       if (!response.success) return;

  //       const list = response.company_vendor_orgs;

  //       setVendorOrgList(list);
  //       setDataSource(dataSourceOptions(reducedVendorOrgList(list)));
  //     });
  // }, []);

  // const searchedDataSource = value => {
  //   const reducedList = reducedVendorOrgList(vendorOrgList);
  //   const filteredDataSource = dataSourceOptions(reducedList).filter(opt => orgFilterOption(value, opt));

  //   return filteredDataSource;
  // };

  // const handleSearch = value => {
  //   const filtered = searchedDataSource(value);
  //   setDataSource(filtered);
  // };

  // const handleSelect = (value, options) => {
  //   const id = value;
  //   const name = options.props.orgname;

  //   setOrgStep({
  //     currentOrgStep: 'confirm_affiliation',
  //     vendorOrg: { id, name }
  //   });
  // };

  return (
    <>
      <Row>
        <Col xs={24}>
          <div className='title'>{TITLE}</div>
        </Col>
      </Row>
      <Row style={{ marginTop: 15 }}>
        <Col xs={24}>
          <div style={{ color: '#95979e' }}>{SUBTITLE}</div>
        </Col>
      </Row>
      {/* Uncomment when we allow searching of orgs */}
      {/*
      <Row gutter={16} style={{ marginTop: 30 }} justify='center'>
        <Col xs={24} sm={22} xl={22}>
          <Search
            dataSource={dataSource}
            onSearch={handleSearch}
            onSelect={handleSelect}
            placeholder={SEARCH_PLACEHOLDER}
            noDataText='No Organizations Found'
            filterOption={orgFilterOption}
            autoComplete
          />
        </Col>
      </Row>
      <Row gutter={16} justify='center'>
        <Col xs={22} xl={20}>
          <OrDivider />
        </Col>
      </Row>
      */}
      <Row gutter={16} justify='center' style={{ marginTop: 30 }}>
        <Col>
          <Button
            onClick={() => {
              setDisabled(true);
              setOrgStep({ currentOrgStep: 'create_org' });
            }}
            type="secondary"
            disabled={disabled}
          >
            {t('general.create_new_organization')}
          </Button>
        </Col>
      </Row>
      <Footer />
    </>
  );
};

FindOrCreateOrganization.propTypes = {
  setOrgStep: PropTypes.func.isRequired
};

export default FindOrCreateOrganization;
