module.exports = {
    "scalars": [
        27,
        34,
        38,
        65,
        66,
        67,
        68,
        69,
        97
    ],
    "types": {
        "AnchorPrice": {
            "anchorPriceLineItems": [
                3
            ],
            "anchorPriceLots": [
                6
            ],
            "bidRequest": [
                23
            ],
            "bidRequestId": [
                66
            ],
            "createdAt": [
                67
            ],
            "id": [
                66
            ],
            "updatedAt": [
                67
            ],
            "vendorEventGroupId": [
                66
            ],
            "vendorOrganization": [
                121
            ],
            "vendorOrganizationId": [
                66
            ],
            "vendorOrganizationName": [
                97
            ],
            "__typename": [
                97
            ]
        },
        "AnchorPriceConnection": {
            "edges": [
                2
            ],
            "nodes": [
                0
            ],
            "pageInfo": [
                80
            ],
            "totalCount": [
                68
            ],
            "__typename": [
                97
            ]
        },
        "AnchorPriceEdge": {
            "cursor": [
                97
            ],
            "node": [
                0
            ],
            "__typename": [
                97
            ]
        },
        "AnchorPriceLineItem": {
            "algorithmicUnitPrice": [
                65
            ],
            "anchorPriceLotId": [
                66
            ],
            "customUnitPrice": [
                65
            ],
            "id": [
                66
            ],
            "isPredictedPriceLowerThanAlgorithmicPrice": [
                38
            ],
            "isPredictedPriceLowerThanBasePrice": [
                38
            ],
            "lineItemId": [
                66
            ],
            "predictiveUnitPrice": [
                65
            ],
            "quantityOfUnits": [
                68
            ],
            "unitPrice": [
                65
            ],
            "usesSuggestedPricing": [
                38
            ],
            "vendorOrganizationId": [
                66
            ],
            "__typename": [
                97
            ]
        },
        "AnchorPriceLineItemConnection": {
            "edges": [
                5
            ],
            "nodes": [
                3
            ],
            "pageInfo": [
                80
            ],
            "totalCount": [
                68
            ],
            "__typename": [
                97
            ]
        },
        "AnchorPriceLineItemEdge": {
            "cursor": [
                97
            ],
            "node": [
                3
            ],
            "__typename": [
                97
            ]
        },
        "AnchorPriceLot": {
            "anchorPriceId": [
                66
            ],
            "anchorPriceLineItems": [
                3
            ],
            "id": [
                66
            ],
            "lotId": [
                66
            ],
            "totalPrice": [
                65
            ],
            "__typename": [
                97
            ]
        },
        "AnchorPriceLotConnection": {
            "edges": [
                8
            ],
            "nodes": [
                6
            ],
            "pageInfo": [
                80
            ],
            "totalCount": [
                68
            ],
            "__typename": [
                97
            ]
        },
        "AnchorPriceLotEdge": {
            "cursor": [
                97
            ],
            "node": [
                6
            ],
            "__typename": [
                97
            ]
        },
        "AnswerOption": {
            "answerOptionTypeId": [
                68
            ],
            "id": [
                66
            ],
            "text": [
                97
            ],
            "visible": [
                38
            ],
            "wasSeeded": [
                38
            ],
            "__typename": [
                97
            ]
        },
        "AsyncLoad": {
            "asyncLoadableId": [
                66
            ],
            "asyncLoadableType": [
                97
            ],
            "createdAt": [
                67
            ],
            "id": [
                66
            ],
            "jobClass": [
                97
            ],
            "modelClass": [
                97
            ],
            "status": [
                97
            ],
            "updatedAt": [
                67
            ],
            "__typename": [
                97
            ]
        },
        "AwardAllInput": {
            "clientMutationId": [
                97
            ],
            "awardScenarioId": [
                66
            ],
            "userId": [
                66
            ],
            "__typename": [
                97
            ]
        },
        "AwardAllPayload": {
            "awardScenario": [
                13
            ],
            "clientMutationId": [
                97
            ],
            "__typename": [
                97
            ]
        },
        "AwardScenario": {
            "asyncLoads": [
                69
            ],
            "awardFormatSelected": [
                97
            ],
            "awardPrice": [
                65
            ],
            "awardedDate": [
                67
            ],
            "awardedVendorOrganizationIds": [
                68
            ],
            "awardeeSelections": [
                20,
                {
                    "after": [
                        97
                    ],
                    "before": [
                        97
                    ],
                    "first": [
                        68
                    ],
                    "last": [
                        68
                    ],
                    "where": [
                        19
                    ]
                }
            ],
            "awardeeSelectionsCount": [
                68
            ],
            "baseAwardPrice": [
                65
            ],
            "baselinePrice": [
                65
            ],
            "bidRequest": [
                23
            ],
            "bidRequestId": [
                68
            ],
            "coverage": [
                65
            ],
            "coveredBaselinePrice": [
                65
            ],
            "createdAt": [
                67
            ],
            "id": [
                66
            ],
            "noBidChecked": [
                38
            ],
            "popularScenarioSelected": [
                97
            ],
            "qualQuestionsChecked": [
                68
            ],
            "requestTcosChecked": [
                68
            ],
            "savingsPercent": [
                65
            ],
            "title": [
                97
            ],
            "totalAdjustedAwardPrice": [
                65
            ],
            "updatedAt": [
                67
            ],
            "valueAdjustedAwardPrice": [
                65
            ],
            "valueAdjustedSavingsPercent": [
                65
            ],
            "__typename": [
                97
            ]
        },
        "AwardWinningSupplier": {
            "containsSplitLineItemAward": [
                38
            ],
            "count": [
                65
            ],
            "id": [
                66
            ],
            "supplierName": [
                97
            ],
            "totalAdjustedAwardPrice": [
                65
            ],
            "valueAdjustedSavingsPercent": [
                65
            ],
            "vendorOrganizationId": [
                66
            ],
            "__typename": [
                97
            ]
        },
        "AwardWinningSupplierAttributes": {
            "bidRequestId": [
                66
            ],
            "scenarioId": [
                66
            ],
            "__typename": [
                97
            ]
        },
        "AwardWinningSupplierConnection": {
            "edges": [
                17
            ],
            "nodes": [
                14
            ],
            "pageInfo": [
                80
            ],
            "totalCount": [
                68
            ],
            "__typename": [
                97
            ]
        },
        "AwardWinningSupplierEdge": {
            "cursor": [
                97
            ],
            "node": [
                14
            ],
            "__typename": [
                97
            ]
        },
        "AwardeeSelection": {
            "awardScenario": [
                13
            ],
            "id": [
                66
            ],
            "lineItem": [
                70
            ],
            "lot": [
                74
            ],
            "quantityOfUnits": [
                68
            ],
            "user": [
                117
            ],
            "userId": [
                66
            ],
            "__typename": [
                97
            ]
        },
        "AwardeeSelectionAttributes": {
            "id": [
                66
            ],
            "userId": [
                66
            ],
            "lineItemId": [
                66
            ],
            "lotId": [
                66
            ],
            "awardScenarioId": [
                66
            ],
            "__typename": [
                97
            ]
        },
        "AwardeeSelectionConnection": {
            "edges": [
                21
            ],
            "nodes": [
                18
            ],
            "pageInfo": [
                80
            ],
            "totalCount": [
                68
            ],
            "__typename": [
                97
            ]
        },
        "AwardeeSelectionEdge": {
            "cursor": [
                97
            ],
            "node": [
                18
            ],
            "__typename": [
                97
            ]
        },
        "BaseSortingAttributes": {
            "columnKey": [
                97
            ],
            "order": [
                97
            ],
            "__typename": [
                97
            ]
        },
        "BidRequest": {
            "anchorPricingDisabled": [
                38
            ],
            "archived": [
                38
            ],
            "asyncLoads": [
                10
            ],
            "automated": [
                38
            ],
            "awardPhaseEndAt": [
                67
            ],
            "awardScenarios": [
                13
            ],
            "biddingEvent": [
                36
            ],
            "businessUnitId": [
                68
            ],
            "businessUnitName": [
                97
            ],
            "buyerEmailText": [
                97
            ],
            "cloneParentId": [
                68
            ],
            "company": [
                43
            ],
            "companyId": [
                68
            ],
            "contractLength": [
                65
            ],
            "contractLengthUnit": [
                68
            ],
            "createdAt": [
                67
            ],
            "creator": [
                117
            ],
            "currency": [
                97
            ],
            "currentRound": [
                92
            ],
            "currentRoundNumber": [
                68
            ],
            "description": [
                97
            ],
            "endTime": [
                67
            ],
            "expectedPurchaseDate": [
                67
            ],
            "expectedSavingsPercentage": [
                65
            ],
            "id": [
                66
            ],
            "isPublic": [
                38
            ],
            "isTest": [
                38
            ],
            "lastResponseUpdate": [
                67
            ],
            "lots": [
                76,
                {
                    "after": [
                        97
                    ],
                    "before": [
                        97
                    ],
                    "first": [
                        68
                    ],
                    "last": [
                        68
                    ],
                    "where": [
                        75
                    ]
                }
            ],
            "lowBidPriceRound": [
                38
            ],
            "multiAward": [
                38
            ],
            "name": [
                97
            ],
            "nextDeadline": [
                79
            ],
            "oneOff": [
                38
            ],
            "openForViewingAt": [
                67
            ],
            "organizationsWithEmailBidSubmissionErrors": [
                68
            ],
            "overallIncumbentId": [
                68
            ],
            "owners": [
                117
            ],
            "remindersSent": [
                68
            ],
            "requestType": [
                97
            ],
            "requiredLineItemIds": [
                68
            ],
            "requiredLineItemQuestionIds": [
                68
            ],
            "responseCount": [
                68
            ],
            "roundCount": [
                68
            ],
            "sendEventReminders": [
                38
            ],
            "startTime": [
                67
            ],
            "state": [
                97
            ],
            "tags": [
                97
            ],
            "template": [
                38
            ],
            "timeZone": [
                97
            ],
            "updatedAt": [
                67
            ],
            "users": [
                119,
                {
                    "after": [
                        97
                    ],
                    "before": [
                        97
                    ],
                    "first": [
                        68
                    ],
                    "last": [
                        68
                    ],
                    "where": [
                        118
                    ]
                }
            ],
            "vendorEmailText": [
                97
            ],
            "version": [
                97
            ],
            "__typename": [
                97
            ]
        },
        "BidRequestAttributes": {
            "state": [
                97
            ],
            "requestType": [
                97
            ],
            "id": [
                66
            ],
            "userId": [
                66
            ],
            "matching": [
                97
            ],
            "template": [
                38
            ],
            "isOpenRequest": [
                38
            ],
            "businessUnitId": [
                66
            ],
            "eventOwnerId": [
                66
            ],
            "__typename": [
                97
            ]
        },
        "BidRequestConnection": {
            "counts": [
                29
            ],
            "edges": [
                26
            ],
            "nodes": [
                23
            ],
            "pageInfo": [
                80
            ],
            "totalCount": [
                68
            ],
            "unscopedCount": [
                68
            ],
            "__typename": [
                97
            ]
        },
        "BidRequestEdge": {
            "cursor": [
                97
            ],
            "node": [
                23
            ],
            "__typename": [
                97
            ]
        },
        "BidRequestSortEnum": {},
        "BidRequestSortingAttributes": {
            "columnKey": [
                27
            ],
            "order": [
                97
            ],
            "__typename": [
                97
            ]
        },
        "BidRequestsCounts": {
            "awarded": [
                68
            ],
            "closed": [
                68
            ],
            "draft": [
                68
            ],
            "information": [
                68
            ],
            "openForBidding": [
                68
            ],
            "openForQuestions": [
                68
            ],
            "openForSubmission": [
                68
            ],
            "proposal": [
                68
            ],
            "purchaseRequest": [
                68
            ],
            "quote": [
                68
            ],
            "readyToAward": [
                68
            ],
            "responseSubmitted": [
                68
            ],
            "unawarded": [
                68
            ],
            "__typename": [
                97
            ]
        },
        "BidResponse": {
            "archived": [
                38
            ],
            "bidRequest": [
                23
            ],
            "bidType": [
                97
            ],
            "id": [
                66
            ],
            "responseLineItems": [
                90,
                {
                    "lineItemId": [
                        66
                    ]
                }
            ],
            "roundNo": [
                68
            ],
            "roundType": [
                97
            ],
            "status": [
                97
            ],
            "total": [
                65
            ],
            "user": [
                117
            ],
            "__typename": [
                97
            ]
        },
        "BidResponseAttributes": {
            "id": [
                66
            ],
            "userId": [
                66
            ],
            "bidRequestId": [
                66
            ],
            "status": [
                97
            ],
            "bidType": [
                97
            ],
            "__typename": [
                97
            ]
        },
        "BidResponseConnection": {
            "edges": [
                33
            ],
            "nodes": [
                30
            ],
            "pageInfo": [
                80
            ],
            "totalCount": [
                68
            ],
            "__typename": [
                97
            ]
        },
        "BidResponseEdge": {
            "cursor": [
                97
            ],
            "node": [
                30
            ],
            "__typename": [
                97
            ]
        },
        "BidResponseSortEnum": {},
        "BidResponseSortingAttributes": {
            "columnKey": [
                34
            ],
            "order": [
                97
            ],
            "__typename": [
                97
            ]
        },
        "BiddingEvent": {
            "bidRequestId": [
                68
            ],
            "createdAt": [
                67
            ],
            "endAt": [
                67
            ],
            "eventFormat": [
                97
            ],
            "hasPriceGuidanceRound": [
                38
            ],
            "id": [
                66
            ],
            "roundCount": [
                68
            ],
            "roundLength": [
                65
            ],
            "roundUnit": [
                97
            ],
            "shouldSkipWeekends": [
                38
            ],
            "startAt": [
                67
            ],
            "updatedAt": [
                67
            ],
            "__typename": [
                97
            ]
        },
        "BlacklistedNotification": {
            "createdAt": [
                67
            ],
            "deliveryMethod": [
                97
            ],
            "event": [
                97
            ],
            "id": [
                66
            ],
            "notifiableId": [
                66
            ],
            "notifiableType": [
                97
            ],
            "updatedAt": [
                67
            ],
            "userId": [
                66
            ],
            "__typename": [
                97
            ]
        },
        "Boolean": {},
        "Category": {
            "code": [
                97
            ],
            "companyId": [
                68
            ],
            "createdAt": [
                67
            ],
            "description": [
                97
            ],
            "id": [
                66
            ],
            "name": [
                97
            ],
            "parentId": [
                68
            ],
            "updatedAt": [
                67
            ],
            "__typename": [
                97
            ]
        },
        "CategoryAttributes": {
            "matching": [
                97
            ],
            "group": [
                97
            ],
            "parentId": [
                68
            ],
            "__typename": [
                97
            ]
        },
        "CategoryConnection": {
            "edges": [
                42
            ],
            "nodes": [
                39
            ],
            "pageInfo": [
                80
            ],
            "totalCount": [
                68
            ],
            "__typename": [
                97
            ]
        },
        "CategoryEdge": {
            "cursor": [
                97
            ],
            "node": [
                39
            ],
            "__typename": [
                97
            ]
        },
        "Company": {
            "adminRoles": [
                91
            ],
            "awardPricePrecision": [
                68
            ],
            "companyType": [
                68
            ],
            "counts": [
                48
            ],
            "createdAt": [
                67
            ],
            "id": [
                66
            ],
            "logo": [
                97
            ],
            "name": [
                97
            ],
            "planType": [
                97
            ],
            "roles": [
                91
            ],
            "seats": [
                68
            ],
            "status": [
                68
            ],
            "subdomain": [
                97
            ],
            "updatedAt": [
                67
            ],
            "uuid": [
                97
            ],
            "webUrl": [
                97
            ],
            "__typename": [
                97
            ]
        },
        "CompanyAttributes": {
            "id": [
                66
            ],
            "name": [
                97
            ],
            "__typename": [
                97
            ]
        },
        "CompanyConnection": {
            "edges": [
                46
            ],
            "nodes": [
                43
            ],
            "pageInfo": [
                80
            ],
            "totalCount": [
                68
            ],
            "__typename": [
                97
            ]
        },
        "CompanyEdge": {
            "cursor": [
                97
            ],
            "node": [
                43
            ],
            "__typename": [
                97
            ]
        },
        "CompanyUserInviteAcceptance": {
            "acceptance": [
                97
            ],
            "buyerId": [
                68
            ],
            "companyId": [
                68
            ],
            "createdAt": [
                67
            ],
            "id": [
                66
            ],
            "referralType": [
                97
            ],
            "updatedAt": [
                67
            ],
            "userId": [
                68
            ],
            "__typename": [
                97
            ]
        },
        "Counts": {
            "division": [
                104
            ],
            "supplier": [
                104
            ],
            "__typename": [
                97
            ]
        },
        "CreateAwardeeSelectionsInput": {
            "clientMutationId": [
                97
            ],
            "awardScenarioId": [
                66
            ],
            "userId": [
                66
            ],
            "lineItemId": [
                66
            ],
            "lotId": [
                66
            ],
            "__typename": [
                97
            ]
        },
        "CreateAwardeeSelectionsPayload": {
            "awardeeSelection": [
                18
            ],
            "clientMutationId": [
                97
            ],
            "__typename": [
                97
            ]
        },
        "CustomColumnHeader": {
            "answerOption": [
                9
            ],
            "characterLimit": [
                68
            ],
            "columnOrderIndex": [
                68
            ],
            "columnType": [
                97
            ],
            "displayText": [
                97
            ],
            "id": [
                66
            ],
            "isSupplierVisible": [
                38
            ],
            "lot": [
                74
            ],
            "qualQuestionId": [
                68
            ],
            "qualifyingEquality": [
                68
            ],
            "__typename": [
                97
            ]
        },
        "CustomColumnHeaderConnection": {
            "edges": [
                53
            ],
            "nodes": [
                51
            ],
            "pageInfo": [
                80
            ],
            "totalCount": [
                68
            ],
            "__typename": [
                97
            ]
        },
        "CustomColumnHeaderEdge": {
            "cursor": [
                97
            ],
            "node": [
                51
            ],
            "__typename": [
                97
            ]
        },
        "CustomColumnItem": {
            "answerOption": [
                9
            ],
            "columnType": [
                97
            ],
            "customColumnHeader": [
                51
            ],
            "displayText": [
                97
            ],
            "id": [
                66
            ],
            "isSupplierVisible": [
                38
            ],
            "lineItem": [
                70
            ],
            "qualifyingEquality": [
                68
            ],
            "__typename": [
                97
            ]
        },
        "CustomColumnItemConnection": {
            "edges": [
                56
            ],
            "nodes": [
                54
            ],
            "pageInfo": [
                80
            ],
            "totalCount": [
                68
            ],
            "__typename": [
                97
            ]
        },
        "CustomColumnItemEdge": {
            "cursor": [
                97
            ],
            "node": [
                54
            ],
            "__typename": [
                97
            ]
        },
        "Division": {
            "categories": [
                39
            ],
            "companyId": [
                68
            ],
            "createdAt": [
                67
            ],
            "dateAdded": [
                67
            ],
            "externalIdentifier": [
                97
            ],
            "firm": [
                64
            ],
            "firmId": [
                68
            ],
            "hq": [
                38
            ],
            "id": [
                66
            ],
            "name": [
                97
            ],
            "status": [
                97
            ],
            "updatedAt": [
                67
            ],
            "vendorOrganization": [
                121
            ],
            "vendorOrganizationId": [
                68
            ],
            "__typename": [
                97
            ]
        },
        "DivisionAttributes": {
            "category": [
                97
            ],
            "matching": [
                97
            ],
            "group": [
                97
            ],
            "approvalStatus": [
                97
            ],
            "bidRequestIds": [
                97
            ],
            "__typename": [
                97
            ]
        },
        "DivisionConnection": {
            "edges": [
                60
            ],
            "nodes": [
                57
            ],
            "pageInfo": [
                80
            ],
            "totalCount": [
                68
            ],
            "__typename": [
                97
            ]
        },
        "DivisionEdge": {
            "cursor": [
                97
            ],
            "node": [
                57
            ],
            "__typename": [
                97
            ]
        },
        "EmailBidSubmission": {
            "companyId": [
                66
            ],
            "createdAt": [
                67
            ],
            "createdBy": [
                97
            ],
            "email": [
                97
            ],
            "embedToken": [
                97
            ],
            "externalId": [
                97
            ],
            "fileName": [
                97
            ],
            "id": [
                66
            ],
            "lastMessage": [
                97
            ],
            "loadType": [
                97
            ],
            "numberOfRows": [
                68
            ],
            "organizationId": [
                66
            ],
            "organizationType": [
                97
            ],
            "source": [
                97
            ],
            "status": [
                97
            ],
            "submittableId": [
                66
            ],
            "type": [
                97
            ],
            "updatedAt": [
                67
            ],
            "__typename": [
                97
            ]
        },
        "EmailBidSubmissionConnection": {
            "edges": [
                63
            ],
            "nodes": [
                61
            ],
            "pageInfo": [
                80
            ],
            "totalCount": [
                68
            ],
            "__typename": [
                97
            ]
        },
        "EmailBidSubmissionEdge": {
            "cursor": [
                97
            ],
            "node": [
                61
            ],
            "__typename": [
                97
            ]
        },
        "Firm": {
            "createdAt": [
                67
            ],
            "description": [
                97
            ],
            "domain": [
                97
            ],
            "employeeCount": [
                68
            ],
            "founded": [
                67
            ],
            "id": [
                66
            ],
            "name": [
                97
            ],
            "refreshedDiversityAt": [
                67
            ],
            "refreshedFirmographyAt": [
                67
            ],
            "revenue": [
                65
            ],
            "updatedAt": [
                67
            ],
            "website": [
                97
            ],
            "yearsInBusiness": [
                68
            ],
            "__typename": [
                97
            ]
        },
        "Float": {},
        "ID": {},
        "ISO8601DateTime": {},
        "Int": {},
        "JSON": {},
        "LineItem": {
            "awardeeSelections": [
                20,
                {
                    "after": [
                        97
                    ],
                    "before": [
                        97
                    ],
                    "first": [
                        68
                    ],
                    "last": [
                        68
                    ],
                    "awardScenarioId": [
                        66
                    ]
                }
            ],
            "createdAt": [
                67
            ],
            "customColumnHeaders": [
                52,
                {
                    "after": [
                        97
                    ],
                    "before": [
                        97
                    ],
                    "first": [
                        68
                    ],
                    "last": [
                        68
                    ],
                    "columnType": [
                        97
                    ]
                }
            ],
            "customColumnItems": [
                55,
                {
                    "after": [
                        97
                    ],
                    "before": [
                        97
                    ],
                    "first": [
                        68
                    ],
                    "last": [
                        68
                    ],
                    "columnType": [
                        97
                    ]
                }
            ],
            "description": [
                97
            ],
            "extendedQuantity": [
                65
            ],
            "id": [
                66
            ],
            "isRequired": [
                38
            ],
            "lotId": [
                68
            ],
            "name": [
                97
            ],
            "price": [
                65
            ],
            "quantity": [
                65
            ],
            "sno": [
                68
            ],
            "supplierQualAnswers": [
                103
            ],
            "systemModified": [
                38
            ],
            "title": [
                97
            ],
            "units": [
                97
            ],
            "updatedAt": [
                67
            ],
            "__typename": [
                97
            ]
        },
        "LineItemAttributes": {
            "id": [
                66
            ],
            "matching": [
                97
            ],
            "customColumnHeaderIdsSelected": [
                66
            ],
            "__typename": [
                97
            ]
        },
        "LineItemConnection": {
            "edges": [
                73
            ],
            "nodes": [
                70
            ],
            "pageInfo": [
                80
            ],
            "totalCount": [
                68
            ],
            "__typename": [
                97
            ]
        },
        "LineItemEdge": {
            "cursor": [
                97
            ],
            "node": [
                70
            ],
            "__typename": [
                97
            ]
        },
        "Lot": {
            "associatedCriteriaIds": [
                68
            ],
            "awardeeSelections": [
                20,
                {
                    "after": [
                        97
                    ],
                    "before": [
                        97
                    ],
                    "first": [
                        68
                    ],
                    "last": [
                        68
                    ],
                    "awardScenarioId": [
                        66
                    ]
                }
            ],
            "baselineTotal": [
                65
            ],
            "createdAt": [
                67
            ],
            "description": [
                97
            ],
            "id": [
                66
            ],
            "lineItems": [
                72,
                {
                    "after": [
                        97
                    ],
                    "before": [
                        97
                    ],
                    "first": [
                        68
                    ],
                    "last": [
                        68
                    ]
                }
            ],
            "project": [
                81
            ],
            "projectId": [
                68
            ],
            "sno": [
                68
            ],
            "title": [
                97
            ],
            "updatedAt": [
                67
            ],
            "__typename": [
                97
            ]
        },
        "LotAttributes": {
            "id": [
                66
            ],
            "bidRequestId": [
                66
            ],
            "name": [
                97
            ],
            "matching": [
                97
            ],
            "__typename": [
                97
            ]
        },
        "LotConnection": {
            "edges": [
                77
            ],
            "nodes": [
                74
            ],
            "pageInfo": [
                80
            ],
            "totalCount": [
                68
            ],
            "__typename": [
                97
            ]
        },
        "LotEdge": {
            "cursor": [
                97
            ],
            "node": [
                74
            ],
            "__typename": [
                97
            ]
        },
        "Mutation": {
            "awardAll": [
                12,
                {
                    "input": [
                        11,
                        "AwardAllInput!"
                    ]
                }
            ],
            "createAwardeeSelections": [
                50,
                {
                    "input": [
                        49,
                        "CreateAwardeeSelectionsInput!"
                    ]
                }
            ],
            "saveMultiAwardeeSelectionsByLineItem": [
                94,
                {
                    "input": [
                        93,
                        "SaveMultiAwardeeSelectionsByLineItemInput!"
                    ]
                }
            ],
            "updateAwardeeSelections": [
                116,
                {
                    "input": [
                        115,
                        "UpdateAwardeeSelectionsInput!"
                    ]
                }
            ],
            "__typename": [
                97
            ]
        },
        "NextDeadline": {
            "date": [
                67
            ],
            "name": [
                97
            ],
            "__typename": [
                97
            ]
        },
        "PageInfo": {
            "endCursor": [
                97
            ],
            "hasNextPage": [
                38
            ],
            "hasPreviousPage": [
                38
            ],
            "startCursor": [
                97
            ],
            "__typename": [
                97
            ]
        },
        "Project": {
            "anchorPricingDisabled": [
                38
            ],
            "archived": [
                38
            ],
            "buyerEmailText": [
                97
            ],
            "cloneParentId": [
                68
            ],
            "companyId": [
                68
            ],
            "contractLength": [
                65
            ],
            "contractLengthUnit": [
                68
            ],
            "createdAt": [
                67
            ],
            "currency": [
                97
            ],
            "description": [
                97
            ],
            "endTime": [
                67
            ],
            "expectedPurchaseDate": [
                67
            ],
            "expectedSavingsPercentage": [
                65
            ],
            "id": [
                66
            ],
            "isPublic": [
                38
            ],
            "lastResponseUpdate": [
                67
            ],
            "lots": [
                74
            ],
            "lowBidPriceRound": [
                38
            ],
            "multiAward": [
                38
            ],
            "oneOff": [
                38
            ],
            "openForViewingAt": [
                67
            ],
            "overallIncumbentId": [
                68
            ],
            "remindersSent": [
                68
            ],
            "roundCount": [
                68
            ],
            "rounds": [
                69
            ],
            "sendEventReminders": [
                38
            ],
            "startTime": [
                67
            ],
            "state": [
                68
            ],
            "tags": [
                97
            ],
            "template": [
                38
            ],
            "timeZone": [
                97
            ],
            "title": [
                97
            ],
            "uiConfig": [
                69
            ],
            "updatedAt": [
                67
            ],
            "vendorEmailText": [
                97
            ],
            "version": [
                97
            ],
            "__typename": [
                97
            ]
        },
        "Query": {
            "anchorPriceLineItems": [
                4,
                {
                    "sorting": [
                        22
                    ],
                    "bidRequestId": [
                        66,
                        "ID!"
                    ],
                    "anchorPriceLotIds": [
                        66,
                        "[ID!]!"
                    ],
                    "after": [
                        97
                    ],
                    "before": [
                        97
                    ],
                    "first": [
                        68
                    ],
                    "last": [
                        68
                    ]
                }
            ],
            "anchorPriceLots": [
                7,
                {
                    "sorting": [
                        22
                    ],
                    "bidRequestId": [
                        66,
                        "ID!"
                    ],
                    "anchorPriceIds": [
                        66,
                        "[ID!]"
                    ],
                    "lotIds": [
                        66,
                        "[ID!]"
                    ],
                    "anchorPriceLotIds": [
                        66,
                        "[ID!]"
                    ],
                    "after": [
                        97
                    ],
                    "before": [
                        97
                    ],
                    "first": [
                        68
                    ],
                    "last": [
                        68
                    ]
                }
            ],
            "anchorPrices": [
                1,
                {
                    "sorting": [
                        22
                    ],
                    "bidRequestId": [
                        66,
                        "ID!"
                    ],
                    "vendorOrganizationName": [
                        97
                    ],
                    "after": [
                        97
                    ],
                    "before": [
                        97
                    ],
                    "first": [
                        68
                    ],
                    "last": [
                        68
                    ]
                }
            ],
            "awardWinningSuppliers": [
                16,
                {
                    "sorting": [
                        22
                    ],
                    "where": [
                        15
                    ],
                    "after": [
                        97
                    ],
                    "before": [
                        97
                    ],
                    "first": [
                        68
                    ],
                    "last": [
                        68
                    ]
                }
            ],
            "bidRequests": [
                25,
                {
                    "sorting": [
                        28
                    ],
                    "where": [
                        24
                    ],
                    "after": [
                        97
                    ],
                    "before": [
                        97
                    ],
                    "first": [
                        68
                    ],
                    "last": [
                        68
                    ]
                }
            ],
            "bidResponses": [
                32,
                {
                    "sorting": [
                        35
                    ],
                    "where": [
                        31
                    ],
                    "after": [
                        97
                    ],
                    "before": [
                        97
                    ],
                    "first": [
                        68
                    ],
                    "last": [
                        68
                    ]
                }
            ],
            "blacklistedNotifications": [
                37,
                {
                    "sorting": [
                        22
                    ],
                    "notifiableId": [
                        66
                    ],
                    "notifiableType": [
                        97
                    ]
                }
            ],
            "categories": [
                41,
                {
                    "sorting": [
                        22
                    ],
                    "where": [
                        40
                    ],
                    "after": [
                        97
                    ],
                    "before": [
                        97
                    ],
                    "first": [
                        68
                    ],
                    "last": [
                        68
                    ]
                }
            ],
            "companies": [
                45,
                {
                    "sorting": [
                        22
                    ],
                    "where": [
                        44
                    ],
                    "after": [
                        97
                    ],
                    "before": [
                        97
                    ],
                    "first": [
                        68
                    ],
                    "last": [
                        68
                    ]
                }
            ],
            "company": [
                43
            ],
            "contacts": [
                101,
                {
                    "sorting": [
                        22
                    ],
                    "where": [
                        99
                    ],
                    "after": [
                        97
                    ],
                    "before": [
                        97
                    ],
                    "first": [
                        68
                    ],
                    "last": [
                        68
                    ]
                }
            ],
            "divisions": [
                59,
                {
                    "sorting": [
                        22
                    ],
                    "where": [
                        58
                    ],
                    "after": [
                        97
                    ],
                    "before": [
                        97
                    ],
                    "first": [
                        68
                    ],
                    "last": [
                        68
                    ]
                }
            ],
            "emailBidSubmissions": [
                62,
                {
                    "sorting": [
                        22
                    ],
                    "where": [
                        89
                    ],
                    "after": [
                        97
                    ],
                    "before": [
                        97
                    ],
                    "first": [
                        68
                    ],
                    "last": [
                        68
                    ]
                }
            ],
            "lineItems": [
                72,
                {
                    "sorting": [
                        22
                    ],
                    "bidRequestId": [
                        66,
                        "[ID!]"
                    ],
                    "lotId": [
                        66,
                        "[ID!]"
                    ],
                    "where": [
                        71
                    ],
                    "after": [
                        97
                    ],
                    "before": [
                        97
                    ],
                    "first": [
                        68
                    ],
                    "last": [
                        68
                    ]
                }
            ],
            "lots": [
                76,
                {
                    "sorting": [
                        22
                    ],
                    "bidRequestId": [
                        66,
                        "[ID!]"
                    ],
                    "where": [
                        75
                    ],
                    "after": [
                        97
                    ],
                    "before": [
                        97
                    ],
                    "first": [
                        68
                    ],
                    "last": [
                        68
                    ]
                }
            ],
            "recommendations": [
                85,
                {
                    "sorting": [
                        22
                    ],
                    "where": [
                        84
                    ],
                    "after": [
                        97
                    ],
                    "before": [
                        97
                    ],
                    "first": [
                        68
                    ],
                    "last": [
                        68
                    ]
                }
            ],
            "recommendedSuppliers": [
                88,
                {
                    "sorting": [
                        22
                    ],
                    "projectId": [
                        68,
                        "Int!"
                    ]
                }
            ],
            "scorers": [
                95,
                {
                    "sorting": [
                        22
                    ],
                    "where": [
                        96
                    ]
                }
            ],
            "supplierTotalQuote": [
                69,
                {
                    "sorting": [
                        22
                    ],
                    "bidRequestId": [
                        66,
                        "ID!"
                    ]
                }
            ],
            "transactionContacts": [
                113,
                {
                    "sorting": [
                        22
                    ],
                    "where": [
                        112,
                        "TransactionSupplierAttributes!"
                    ],
                    "after": [
                        97
                    ],
                    "before": [
                        97
                    ],
                    "first": [
                        68
                    ],
                    "last": [
                        68
                    ]
                }
            ],
            "transactionDivisions": [
                108,
                {
                    "sorting": [
                        22
                    ],
                    "where": [
                        112,
                        "TransactionSupplierAttributes!"
                    ],
                    "after": [
                        97
                    ],
                    "before": [
                        97
                    ],
                    "first": [
                        68
                    ],
                    "last": [
                        68
                    ]
                }
            ],
            "transactions": [
                106,
                {
                    "sorting": [
                        22
                    ],
                    "where": [
                        40
                    ],
                    "after": [
                        97
                    ],
                    "before": [
                        97
                    ],
                    "first": [
                        68
                    ],
                    "last": [
                        68
                    ]
                }
            ],
            "userAwardedLineItemResponses": [
                69,
                {
                    "sorting": [
                        22
                    ],
                    "bidRequestId": [
                        66,
                        "ID!"
                    ],
                    "userToAwardedLineItems": [
                        69,
                        "JSON!"
                    ]
                }
            ],
            "userAwardedLotResponses": [
                69,
                {
                    "sorting": [
                        22
                    ],
                    "bidRequestId": [
                        66,
                        "ID!"
                    ],
                    "userToAwardedLots": [
                        69,
                        "JSON!"
                    ]
                }
            ],
            "users": [
                119,
                {
                    "sorting": [
                        22
                    ],
                    "where": [
                        118
                    ],
                    "after": [
                        97
                    ],
                    "before": [
                        97
                    ],
                    "first": [
                        68
                    ],
                    "last": [
                        68
                    ]
                }
            ],
            "__typename": [
                97
            ]
        },
        "Recommendation": {
            "action": [
                97
            ],
            "companyId": [
                66
            ],
            "createdAt": [
                67
            ],
            "data": [
                69
            ],
            "expiresAt": [
                67
            ],
            "id": [
                66
            ],
            "name": [
                97
            ],
            "purchasingOrgLocation": [
                97
            ],
            "recommendationSubtype": [
                97
            ],
            "recommendationType": [
                97
            ],
            "referenceCategory": [
                97
            ],
            "sentAt": [
                67
            ],
            "text": [
                97
            ],
            "updatedAt": [
                67
            ],
            "__typename": [
                97
            ]
        },
        "RecommendationAttributes": {
            "id": [
                66
            ],
            "matching": [
                97
            ],
            "recommendationSubtype": [
                97
            ],
            "recommendationType": [
                97
            ],
            "referenceCategory": [
                97
            ],
            "__typename": [
                97
            ]
        },
        "RecommendationConnection": {
            "counts": [
                87
            ],
            "edges": [
                86
            ],
            "nodes": [
                83
            ],
            "pageInfo": [
                80
            ],
            "totalCount": [
                68
            ],
            "__typename": [
                97
            ]
        },
        "RecommendationEdge": {
            "cursor": [
                97
            ],
            "node": [
                83
            ],
            "__typename": [
                97
            ]
        },
        "RecommendationsCounts": {
            "poFragmentation": [
                68
            ],
            "priceIncreasing": [
                68
            ],
            "priceIncreasingAndHighSupplierFrag": [
                68
            ],
            "purchasePriceVariation": [
                68
            ],
            "purchasePriceVariationPlusSupplierFrag": [
                68
            ],
            "seasonality": [
                68
            ],
            "suddenPriceIncrease": [
                68
            ],
            "supplierPower": [
                68
            ],
            "__typename": [
                97
            ]
        },
        "RecommendedSupplier": {
            "approvalStatus": [
                97
            ],
            "bidRequestId": [
                66
            ],
            "categories": [
                39
            ],
            "companyInviteAcceptance": [
                47
            ],
            "createdAt": [
                67
            ],
            "divisionId": [
                68
            ],
            "email": [
                97
            ],
            "externalId": [
                97
            ],
            "firstName": [
                97
            ],
            "id": [
                66
            ],
            "inviteStatus": [
                97
            ],
            "lastName": [
                97
            ],
            "lastWorkedWith": [
                67
            ],
            "organization": [
                57
            ],
            "reasons": [
                97
            ],
            "score": [
                65
            ],
            "title": [
                97
            ],
            "updatedAt": [
                67
            ],
            "user": [
                117
            ],
            "vendorProfileId": [
                68
            ],
            "visible": [
                38
            ],
            "__typename": [
                97
            ]
        },
        "RecordSubmissionLoadAttributes": {
            "userId": [
                66
            ],
            "submittableId": [
                66
            ],
            "__typename": [
                97
            ]
        },
        "ResponseLineItem": {
            "bidResponse": [
                30
            ],
            "id": [
                66
            ],
            "lineItemId": [
                68
            ],
            "noBid": [
                38
            ],
            "price": [
                65
            ],
            "total": [
                65
            ],
            "__typename": [
                97
            ]
        },
        "Role": {
            "createdAt": [
                67
            ],
            "id": [
                66
            ],
            "name": [
                97
            ],
            "updatedAt": [
                67
            ],
            "__typename": [
                97
            ]
        },
        "Round": {
            "biddingEventId": [
                68
            ],
            "createdAt": [
                67
            ],
            "description": [
                97
            ],
            "endTime": [
                67
            ],
            "id": [
                66
            ],
            "lengthUnit": [
                97
            ],
            "lengthValue": [
                65
            ],
            "name": [
                97
            ],
            "number": [
                68
            ],
            "roundType": [
                97
            ],
            "startTime": [
                67
            ],
            "updatedAt": [
                67
            ],
            "__typename": [
                97
            ]
        },
        "SaveMultiAwardeeSelectionsByLineItemInput": {
            "clientMutationId": [
                97
            ],
            "awardScenarioId": [
                66
            ],
            "lineItemId": [
                66
            ],
            "awardeesSelectedList": [
                69
            ],
            "__typename": [
                97
            ]
        },
        "SaveMultiAwardeeSelectionsByLineItemPayload": {
            "awardScenario": [
                13
            ],
            "clientMutationId": [
                97
            ],
            "__typename": [
                97
            ]
        },
        "Scorer": {
            "createdAt": [
                67
            ],
            "email": [
                97
            ],
            "id": [
                66
            ],
            "isAccepted": [
                38
            ],
            "name": [
                97
            ],
            "reviewUrl": [
                97
            ],
            "surveyId": [
                68
            ],
            "updatedAt": [
                67
            ],
            "__typename": [
                97
            ]
        },
        "ScorerAttributes": {
            "id": [
                66
            ],
            "requestId": [
                66
            ],
            "__typename": [
                97
            ]
        },
        "String": {},
        "Supplier": {
            "approvalStatus": [
                97
            ],
            "categories": [
                39
            ],
            "companyInviteAcceptance": [
                47
            ],
            "createdAt": [
                67
            ],
            "divisionId": [
                68
            ],
            "email": [
                97
            ],
            "externalId": [
                97
            ],
            "firstName": [
                97
            ],
            "id": [
                66
            ],
            "inviteStatus": [
                97
            ],
            "lastName": [
                97
            ],
            "organization": [
                57
            ],
            "score": [
                65
            ],
            "title": [
                97
            ],
            "updatedAt": [
                67
            ],
            "user": [
                117
            ],
            "vendorProfileId": [
                68
            ],
            "visible": [
                38
            ],
            "__typename": [
                97
            ]
        },
        "SupplierAttributes": {
            "category": [
                97
            ],
            "divisionIds": [
                97
            ],
            "gt": [
                100
            ],
            "lt": [
                100
            ],
            "eq": [
                100
            ],
            "matching": [
                97
            ],
            "approvalStatus": [
                97
            ],
            "inviteStatus": [
                97
            ],
            "bidRequestIds": [
                97
            ],
            "__typename": [
                97
            ]
        },
        "SupplierComparisonAttributes": {
            "spend": [
                68
            ],
            "engagement": [
                68
            ],
            "employees": [
                68
            ],
            "revenue": [
                68
            ],
            "yearsInBusiness": [
                68
            ],
            "__typename": [
                97
            ]
        },
        "SupplierConnection": {
            "edges": [
                102
            ],
            "nodes": [
                98
            ],
            "pageInfo": [
                80
            ],
            "totalCount": [
                68
            ],
            "__typename": [
                97
            ]
        },
        "SupplierEdge": {
            "cursor": [
                97
            ],
            "node": [
                98
            ],
            "__typename": [
                97
            ]
        },
        "SupplierQualAnswer": {
            "answerOptionId": [
                68
            ],
            "answerableId": [
                68
            ],
            "answerableType": [
                97
            ],
            "id": [
                66
            ],
            "isSubmitted": [
                38
            ],
            "optedOut": [
                38
            ],
            "qualQuestionId": [
                68
            ],
            "userId": [
                68
            ],
            "value": [
                97
            ],
            "vendorOrganizationId": [
                68
            ],
            "__typename": [
                97
            ]
        },
        "SupplierStatus": {
            "approved": [
                68
            ],
            "exists": [
                68
            ],
            "pending": [
                68
            ],
            "preferred": [
                68
            ],
            "rejected": [
                68
            ],
            "__typename": [
                97
            ]
        },
        "Transaction": {
            "amount": [
                65
            ],
            "description": [
                97
            ],
            "id": [
                66
            ],
            "kind": [
                97
            ],
            "purchaseDate": [
                67
            ],
            "status": [
                97
            ],
            "title": [
                97
            ],
            "transactableId": [
                68
            ],
            "transactableType": [
                97
            ],
            "__typename": [
                97
            ]
        },
        "TransactionConnection": {
            "edges": [
                110
            ],
            "nodes": [
                105
            ],
            "pageInfo": [
                80
            ],
            "totalCount": [
                68
            ],
            "__typename": [
                97
            ]
        },
        "TransactionDivision": {
            "awarded": [
                38
            ],
            "categories": [
                39
            ],
            "companyId": [
                68
            ],
            "createdAt": [
                67
            ],
            "dateAdded": [
                67
            ],
            "externalIdentifier": [
                97
            ],
            "firm": [
                64
            ],
            "firmId": [
                68
            ],
            "hq": [
                38
            ],
            "id": [
                66
            ],
            "name": [
                97
            ],
            "rank": [
                68
            ],
            "status": [
                97
            ],
            "updatedAt": [
                67
            ],
            "vendorOrganization": [
                121
            ],
            "vendorOrganizationId": [
                68
            ],
            "__typename": [
                97
            ]
        },
        "TransactionDivisionConnection": {
            "edges": [
                109
            ],
            "nodes": [
                107
            ],
            "pageInfo": [
                80
            ],
            "totalCount": [
                68
            ],
            "__typename": [
                97
            ]
        },
        "TransactionDivisionEdge": {
            "cursor": [
                97
            ],
            "node": [
                107
            ],
            "__typename": [
                97
            ]
        },
        "TransactionEdge": {
            "cursor": [
                97
            ],
            "node": [
                105
            ],
            "__typename": [
                97
            ]
        },
        "TransactionSupplier": {
            "approvalStatus": [
                97
            ],
            "awarded": [
                38
            ],
            "categories": [
                39
            ],
            "companyInviteAcceptance": [
                47
            ],
            "createdAt": [
                67
            ],
            "divisionId": [
                68
            ],
            "email": [
                97
            ],
            "externalId": [
                97
            ],
            "firstName": [
                97
            ],
            "id": [
                66
            ],
            "inviteStatus": [
                97
            ],
            "lastName": [
                97
            ],
            "organization": [
                57
            ],
            "rank": [
                68
            ],
            "score": [
                65
            ],
            "title": [
                97
            ],
            "updatedAt": [
                67
            ],
            "user": [
                117
            ],
            "vendorProfileId": [
                68
            ],
            "visible": [
                38
            ],
            "__typename": [
                97
            ]
        },
        "TransactionSupplierAttributes": {
            "category": [
                97
            ],
            "divisionIds": [
                97
            ],
            "gt": [
                100
            ],
            "lt": [
                100
            ],
            "eq": [
                100
            ],
            "matching": [
                97
            ],
            "approvalStatus": [
                97
            ],
            "inviteStatus": [
                97
            ],
            "bidRequestIds": [
                97
            ],
            "transactableId": [
                66
            ],
            "transactableType": [
                97
            ],
            "__typename": [
                97
            ]
        },
        "TransactionSupplierConnection": {
            "edges": [
                114
            ],
            "nodes": [
                111
            ],
            "pageInfo": [
                80
            ],
            "totalCount": [
                68
            ],
            "__typename": [
                97
            ]
        },
        "TransactionSupplierEdge": {
            "cursor": [
                97
            ],
            "node": [
                111
            ],
            "__typename": [
                97
            ]
        },
        "UpdateAwardeeSelectionsInput": {
            "clientMutationId": [
                97
            ],
            "id": [
                66
            ],
            "awardScenarioId": [
                66
            ],
            "userId": [
                66
            ],
            "lineItemId": [
                66
            ],
            "lotId": [
                66
            ],
            "__typename": [
                97
            ]
        },
        "UpdateAwardeeSelectionsPayload": {
            "awardeeSelection": [
                18
            ],
            "clientMutationId": [
                97
            ],
            "__typename": [
                97
            ]
        },
        "User": {
            "bidRequestIds": [
                66
            ],
            "bidResponses": [
                32,
                {
                    "after": [
                        97
                    ],
                    "before": [
                        97
                    ],
                    "first": [
                        68
                    ],
                    "last": [
                        68
                    ],
                    "where": [
                        31
                    ]
                }
            ],
            "category": [
                97
            ],
            "cleanName": [
                97
            ],
            "company": [
                43
            ],
            "createdAt": [
                67
            ],
            "currentSignInAt": [
                67
            ],
            "currentSignInIp": [
                97
            ],
            "deactivated": [
                38
            ],
            "email": [
                97
            ],
            "encryptedPassword": [
                97
            ],
            "failedAttempts": [
                68
            ],
            "firstName": [
                97
            ],
            "id": [
                66
            ],
            "inviteToken": [
                97
            ],
            "inviteTokenCreatedAt": [
                67
            ],
            "inviteTokenRaw": [
                97
            ],
            "inviteUrl": [
                97
            ],
            "isDemoAccount": [
                38
            ],
            "isDummy": [
                38
            ],
            "jobTitle": [
                97
            ],
            "lastName": [
                97
            ],
            "lastNotificationsEmail": [
                67
            ],
            "lastSeenAt": [
                67
            ],
            "lastSignInAt": [
                67
            ],
            "lastSignInIp": [
                97
            ],
            "lastUnreadCount": [
                68
            ],
            "lockedAt": [
                67
            ],
            "newUserReqsSatisfied": [
                38
            ],
            "oldCurrentSignInAt": [
                67
            ],
            "oldLastSignInAt": [
                67
            ],
            "onBoarding": [
                97
            ],
            "onBoardingStep": [
                68
            ],
            "organizationName": [
                97
            ],
            "phone": [
                97
            ],
            "profilePictureUrl": [
                97
            ],
            "profileUpdated": [
                38
            ],
            "provider": [
                97
            ],
            "providerUrl": [
                97
            ],
            "rememberCreatedAt": [
                67
            ],
            "resetPasswordSentAt": [
                67
            ],
            "resetPasswordToken": [
                97
            ],
            "role": [
                68
            ],
            "roles": [
                91
            ],
            "shouldSeeGettingStarted": [
                38
            ],
            "signInCount": [
                68
            ],
            "uid": [
                97
            ],
            "unlockToken": [
                97
            ],
            "updatedAt": [
                67
            ],
            "vendorTags": [
                97
            ],
            "webUrl": [
                97
            ],
            "__typename": [
                97
            ]
        },
        "UserAttributes": {
            "id": [
                66
            ],
            "category": [
                97
            ],
            "__typename": [
                97
            ]
        },
        "UserConnection": {
            "edges": [
                120
            ],
            "nodes": [
                117
            ],
            "pageInfo": [
                80
            ],
            "totalCount": [
                68
            ],
            "__typename": [
                97
            ]
        },
        "UserEdge": {
            "cursor": [
                97
            ],
            "node": [
                117
            ],
            "__typename": [
                97
            ]
        },
        "VendorOrganization": {
            "city": [
                97
            ],
            "country": [
                97
            ],
            "countryCode": [
                97
            ],
            "createdAt": [
                67
            ],
            "description": [
                97
            ],
            "email": [
                97
            ],
            "id": [
                66
            ],
            "lastActive": [
                67
            ],
            "membersCount": [
                68
            ],
            "name": [
                97
            ],
            "numOfEmployees": [
                97
            ],
            "phone": [
                97
            ],
            "publicListed": [
                38
            ],
            "quotesSubmitted": [
                68
            ],
            "score": [
                65
            ],
            "state": [
                97
            ],
            "stateCode": [
                97
            ],
            "updatedAt": [
                67
            ],
            "website": [
                97
            ],
            "__typename": [
                97
            ]
        }
    }
}