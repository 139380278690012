/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from 'prop-types';
import { Tooltip } from '@arkestro/arkestro-design-system';
import { CloseOutlined, InfoCircleFilled } from '@ant-design/icons';
import useTranslation from 'next-translate/useTranslation';
import styles from './InputField.module.scss';

const ERROR_STYLE = { color: '#cd4141', fontSize: 12, marginBottom: 5 };

const InputField = ({
  id,
  labelText,
  name,
  onChange,
  onKeyPress,
  value,
  required,
  textArea,
  rows,
  tooltipText,
  onRemove,
  hasBorders,
  error,
  autoFocus,
  placeholder,
  style,
  readOnly,
  disabled,
  type,
  onClick,
  maxLength,
  onBlur,
  testRef,
  showOptional,
}) => {
  const attrs = {
    id: id || name,
    name,
    value,
    onChange,
    required,
    onKeyPress,
    autoFocus,
    placeholder,
    readOnly,
    onClick,
    disabled,
    'data-qa': testRef,
  };

  if (maxLength) attrs.maxLength = maxLength;

  const { t } = useTranslation('common');
  const errorClass = error && error.length !== 0 ? styles.withError : '';
  const inputFieldClass = hasBorders ? styles.withBorders : '';
  const textInput = (
    <input
      type={type}
      style={style}
      disabled={disabled}
      name={name}
      className={`${styles.input} ${inputFieldClass} ${errorClass}`}
      onBlur={onBlur}
      {...attrs}
    />
  );
  const textAreaInput = (
    <textarea
      rows={rows}
      style={style}
      disabled={disabled}
      name={name}
      className={`${styles.textarea} ${styles.withBorders} ${errorClass}`}
      onBlur={onBlur}
      {...attrs}
    />
  );

  return (
    <>
      <div style={{ position: 'relative' }}>
        {labelText && (
          <label className={styles.label} htmlFor={name}>
            {labelText}
            {required && <span>&nbsp;*</span>}
            {!required && showOptional && <span> ({t('general.optional')})</span>}
            {tooltipText && tooltipText.length > 0 && (
              <Tooltip placement="bottom" title={tooltipText} overlayStyle={{ minWidth: 300 }}>
                <InfoCircleFilled style={{ fontSize: 12, marginLeft: 10 }} />
              </Tooltip>
            )}
          </label>
        )}
        {textArea ? textAreaInput : textInput}
        {onRemove && (
          <span onClick={onRemove} className={styles.closeButton} data-testid='remove-btn'>
            <CloseOutlined />
          </span>
        )}
      </div>
      {error && <div style={ERROR_STYLE}>{error}</div>}
    </>
  );
};

InputField.propTypes = {
  labelText: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onRemove: PropTypes.func,
  onBlur: PropTypes.func,
  hasBorders: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  required: PropTypes.bool,
  textArea: PropTypes.bool,
  rows: PropTypes.number,
  tooltipText: PropTypes.string,
  error: PropTypes.string,
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  style: PropTypes.shape(),
  readOnly: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  maxLength: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  testRef: PropTypes.string,
  showOptional: PropTypes.bool,
};

InputField.defaultProps = {
  onChange: () => {},
  labelText: undefined,
  hasBorders: false,
  value: '',
  required: false,
  textArea: false,
  rows: 5,
  tooltipText: undefined,
  onRemove: undefined,
  error: undefined,
  onKeyPress: () => {},
  onBlur: () => {},
  autoFocus: false,
  placeholder: '',
  style: {},
  readOnly: false,
  type: 'text',
  onClick: () => {},
  disabled: false,
  maxLength: undefined,
  id: null,
  testRef: null,
  showOptional: false,
};

export default InputField;
