import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'next/router';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { getMe, getHealth } from '@api/AuthApi';
import NPSService from '@services/NPSService';
import DatadogService from '@services/DatadogService';
import LaunchDarklyService from '@services/LaunchDarklyService';
import SentryService from '@services/SentryService';
import RedirectService from '@services/RedirectService';
import { ConfigurationsContext } from 'components/providers/ConfigurationsProvider/ConfigurationsProvider';
import { dateSort, numericalSort } from '@utils/sorting';

// 10 minutes
// This should always be smaller than the JWT expiration
const JWT_INTERVAL = 600000;

export const UserContext = React.createContext({
  id: 0,
  setUserProperty: (_any, _callback) => {},
  resetMe: () => {},
  clearUserData: () => {},
  is_buyer: false,
  is_supplier: false,
  is_admin: false,
  hasAttemptedAuth: false,
  global,
  company: {
    award_price_precision: 2
  },
  on_boarding: '',
  category: null,
  can_impersonate: false,
  business_units: [],
});

class UserProvider extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      hasAttemptedAuth: false,
      hasFailedHealthCheck: false
    };
  }

  componentDidMount () {
    this.isSubscribed = true;

    DatadogService.initialize();
    NPSService.initialize();

    this.resetMe();

    // Periodically refreshing the JWT avoids odd redirects and UI flicker when a user navigates to another page
    // during a stale sessions where the JWT has expired.
    this.interval = setInterval(this.healthCheck, JWT_INTERVAL);

    // Browsers will deprioritize background windows/tabs which could lead to stale access tokens that don't
    // get refreshed before a page navigation happens.  Listening for visibility change and firing
    document.addEventListener('visibilitychange', this.healthCheck);
  }

  componentWillUnmount () {
    this.isSubscribed = false;
    clearInterval(this.interval);

    document.removeEventListener('visibilitychange', this.healthCheck);
  }

  // We are only running a health check here to ensure the JWT is active. We don't
  // care about setting any state from this.
  healthCheck = () => {
    if (document.hidden) return;
    if (RedirectService.isAuthenticationPage(this.props.router)) return;

    // If we've failed a health check, we're not signed in and should not continue to health check.
    // Something to think about.. avoiding a healthcheck retrying when signed out.
    // if (this.state.hasFailedHealthCheck) return;

    getHealth();
    // getHealth().then(results => {
    //   if (results?.response?.data?.errors) this.setState({ hasFailedHealthCheck: true });
    // });
  }

  resetMe = () => getMe().then(response => {
    if (!this.isSubscribed) return;

    const { router } = this.props;

    if (response.status !== 200) {
      // null indicates not authorized
      this.setState({ hasAttemptedAuth: true });
      return;
    }

    const { data: user } = response;
    const { sigma_dashboard_embeds: sigmaDashboardEmbeds = [] } = user;

    let orderedSigmaDashboardEmbeds = [];

    if (sigmaDashboardEmbeds) {
      orderedSigmaDashboardEmbeds = dateSort(sigmaDashboardEmbeds, 'updated_at', 'asc')
        .sort((a, b) => (numericalSort(a, b, 'position')));
    }

    this.setState({
      ...{ ...user, sigma_dashboard_embeds: orderedSigmaDashboardEmbeds },
      hasAttemptedAuth: true
    }, () => {
      // If we're logged in and landed on an authentication page, redirect to root.
      // If the user is trying to access a super admin page, redirect.
      // There is a momentary flash of the sign-in page before it redirects. Better way to handle?
      RedirectService.redirectAuthedUser({ user, router });
      // Reconfigure the user here, to handle flagging changes when impersonating a user.
      // ldClient is not guaranteed to exist here on page load, because we don't use the async provider
      // from LD. Because of this, we configure the user also in App.tsx to handle first page load.
      LaunchDarklyService.configureUser(this.props.ldClient, user);
      SentryService.configureUser(user);
      DatadogService.configureUser(user);
    });
  })

  clearUserData = () => {
    this.setState({});
  }

  setUserProperty = (property, callback) => {
    this.setState(property, newState => {
      if (callback) callback(newState);
    });
  }

  render () {
    return (
      <UserContext.Provider
        displayName='UserContext'
        value={{
          ...this.state,
          global: this.props.configurations.global,
          setUserProperty: this.setUserProperty,
          resetMe: this.resetMe,
          clearUserData: this.clearUserData
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
  configurations: PropTypes.shape().isRequired
};

const exportedComponent = props => (
  <ConfigurationsContext.Consumer>
    {configurations => <UserProvider {...props} configurations={configurations}/>}
  </ConfigurationsContext.Consumer>
);

export default withLDConsumer()(withRouter(exportedComponent));
