/* eslint-disable import/no-extraneous-dependencies */
import { Translate } from 'next-translate';
import { guess } from 'binary-type';
import MimeTypes from 'mime-types';

import { IMAGE_MIME_TYPES, TABLE_MIME_TYPES } from './files';

export const MULTIPART_FORM_DATA_HEADER = { 'Content-Type': 'multipart/form-data' };

/** Load file buffer data. */
const loadBuffer = (file: File): Promise<ArrayBuffer> => {
  const promise: Promise<ArrayBuffer> = new Promise<ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (): void => {
      const { result } = reader;

      if (!result) {
        reject(new Error('File reader result is null'));
        return;
      }
      if (typeof result === 'string') {
        resolve(Buffer.from(result));
        return;
      }
      resolve(result);
    };
    reader.readAsArrayBuffer(file);
  });

  return promise;
};

type FileTypeResult = {
  ext: string;
  mime: string;
};

/** Get file type from buffer data and not from file extension. */
const getFileType = async (file: File): Promise<FileTypeResult | undefined> => {
  try {
    const buffer: ArrayBuffer = await loadBuffer(file);
    let result = { ext: '', mime: guess(buffer) };

    if (result?.mime?.length) return result;
    result = { ext: '', mime: MimeTypes.contentType(file.name) };

    if (result?.mime?.length) return result;
    return undefined;
  } catch (error) {
    return undefined;
  }
};

type ArbitraryFileParams = {
  t?: Translate;
  fileName: string;
  extension: string;
};

export const validateArbitraryFile = ({ t, fileName, extension }: ArbitraryFileParams): void => {
  if (extension === 'zip') {
    // .xls, .xlsx, and .numbers files are zip files
    const xlsTypes: string[] = ['xls', 'xlsx', 'numbers'];

    if (xlsTypes.some(type => fileName.endsWith(type))) return;
  }

  // Check if file name ends with extension
  if (!fileName.endsWith(extension))
    throw new Error(t ? t('general.invalid_file_extension') : 'Invalid file type');
};

type BinaryFileParams = {
  t?: Translate;
  fileType: FileTypeResult;
  validTypes?: string[];
};

// A binary file is a computer file that is not a text file.[1] The term "binary file" is often used as a term meaning
// "non-text file".[2] Many binary file formats contain parts that can be interpreted as text;
const validateBinaryFile = ({ t, fileType, validTypes }: BinaryFileParams): void => {
  // Check if file type is binary
  if (fileType.mime !== 'application/octet-stream') return;

  // Check if file type is listed in valid extensions for binary files
  if (validTypes?.length){
    const { ext, mime } = fileType;

    if (ext?.length && validTypes.includes(ext)) return;
    if (mime?.length && validTypes.includes(mime)) return;
    throw new Error(t ? t('general.invalid_file_type') : 'Invalid file type');
  }
  throw new Error(t ? t('general.invalid_file_binary') : 'Invalid binary file');
};

type FileTypeParams = {
  t?: Translate;
  file?: File;
  validTypes?: string[];
  binaryTypesAllowed?: string[];
};

/**
 * Validate file type from buffer data and not from file extension,
 * to avoid file name spoofing (e.g. .exe file renamed to .jpg)
 *
 * @throws Error: Invalid file type or mismatch between file name and type extension
 * @example
 * ```typescript
 * const file = event.target.files[0];
 * validateFileType({
 *   t,
 *   file,
 *   validTypes: ['csv', 'xls', 'xlsx', 'numbers'],
 * });
 * ```
 */
export const validateFileType = async (params: FileTypeParams): Promise<void> => {
  const { t, file, validTypes, binaryTypesAllowed } = params;

  if (!file) return;
  // Get file type from buffer data
  const fileType = await getFileType(file);

  if (!fileType) return;

  // Check if file type is listed in valid extensions for binary files
  if (validTypes?.length){
    const { ext, mime } = fileType;

    if (ext?.length && validTypes.includes(ext)) return;
    if (mime?.length && validTypes.includes(mime)) return;
    throw new Error(t ? t('general.invalid_file_type') : 'Invalid file type');
  }
  validateBinaryFile({ t, fileType, validTypes: binaryTypesAllowed });
};

type ValidParams = {
  file: File;
  validTypes?: string[];
  binaryTypesAllowed?: string[];
};

export const isValidFileType = async (params: ValidParams): Promise<boolean> => {
  const { file, validTypes, binaryTypesAllowed } = params;

  try {
    await validateFileType({ file, validTypes, binaryTypesAllowed });
    return true;
  } catch (err) {
    return false;
  }
};

type ValidateParams = {
  t: Translate;
  file: File;
  binaryTypesAllowed?: string[];
};

export const validateImageFileType = async ({ t, file, binaryTypesAllowed }: ValidateParams): Promise<void> => {
  const validTypes = IMAGE_MIME_TYPES;

  validateFileType({ t, file, validTypes, binaryTypesAllowed });
};

export const validateTableFileType = async ({ t, file, binaryTypesAllowed }: ValidateParams): Promise<void> => {
  const validTypes = TABLE_MIME_TYPES;

  validateFileType({ t, file, validTypes, binaryTypesAllowed });
};
