/* eslint-disable @next/next/no-img-element */
/* eslint-disable camelcase */
import { Component, StrictMode } from 'react';
import { Button, Card } from '@arkestro/arkestro-design-system';
import { LeftCircleOutlined, RightCircleOutlined, RightOutlined } from '@ant-design/icons';
import withTranslation from 'next-translate/withTranslation';
import { withRouter } from 'next/router';
import { UserContext } from '@providers/UserProvider/UserProvider';
import { PALM, CREATE_REQUESTS_GRAPHIC, COLLABORATE_REQUESTS_GRAPHIC, AWARD_GRAPHIC,
  SUPPLIERS_GRAPHIC, EVENTS_GRAPHIC, SMALL_OVAL, SMALL_RECTANGLE } from '@utils/images';
import { alertMessage } from 'utils/common';
import { setOnBoardStep, updateUserProfileFields } from 'api/UserApi';
import {
  getSpendCategories,
  getSpendCategoriesForCurrentUser,
  updateSpendCategoriesForCurrentUser
} from 'api/SpendCategoryApi';
import { FULL_LOGO_BLACK_URL } from 'utils/constants';
import SpendTypesTooltipLine from './SpendTypesTooltipLine';

const graphics = {
  palm: <img src={PALM} alt='Palm' className='palm-onboarding' />,
  oval: <img src={SMALL_OVAL} alt='Oval' className='oval-onboarding' />,
  rectangle: <img src={SMALL_RECTANGLE} alt='Rectangle' className='rectangle-onboarding' />,
  createRequestsGraphic: <img src={CREATE_REQUESTS_GRAPHIC} alt='Rectange' className='requests-onboarding' />,
  eventsGraphic: <img src={EVENTS_GRAPHIC} alt='Rectangle' className='events-onboarding' />,
  collaborateGraphic: <img src={COLLABORATE_REQUESTS_GRAPHIC} alt='Rectangle' className='collaborate-onboarding' />,
  suppliersGraphic: <img src={SUPPLIERS_GRAPHIC} alt='Rectangle' className='supplier-onboarding' />,
  awardGraphic: <img src={AWARD_GRAPHIC} alt='Rectangle' className='award-onboarding' />,
  logo: <img src={FULL_LOGO_BLACK_URL} alt='Logo' className='logo_onboarding' />
};

const DIRECT_SPEND = 'Direct Spend';
const INDIRECT_SPEND = 'Indirect Spend';

const SPEND_CATEGORY_LIST_STYLE = { padding: 20 };

const steps = t => ([
  {
    step: 'intro',
    position: 'first',
    insetPageClassName: 'tall-info-section',
    privacyTermsSkipClassName: 'privacy-terms-line',
    title: t('onboarding.buyer.welcome_title'),
    call: 'buyer_step_one',
    text: '',
    buttonText: t('general.continue'),
    skip: ''
  },
  {
    step: 'buyer_step_one',
    title: t('onboarding.welcome_to_arkestro'),
    insetPageClassName: 'short-info-section',
    privacyTermsSkipClassName: 'privacy-terms-line',
    call: 'start_overview',
    text: t('onboarding.buyer.what_you_can_do'),
    buttonText: t('general.continue'),
    graphic: graphics.palm,
    skip: 'buyer_step_two',
    customTextClass: 'lets-begin-text'
  },
  {
    step: 'buyer_step_two',
    insetPageClassName: 'longer-info-section',
    privacyTermsSkipClassName: 'privacy-terms-line',
    title: t('onboarding.buyer.ready_to_start'),
    text: t('onboarding.buyer.what_spend_types'),
    call: 'completed',
    buttonText: t('general.continue'),
    skip: 'completed',
    customTextClass: 'spend-selection-subtitle'
  }
]);

const overviewSteps = t => ([
  {
    step_position: 0,
    title: t('onboarding.buyer.manage_requests'),
    privacyTermsSkipClassName: 'privacy-terms-line',
    insetPageClassName: 'wider-info-section',
    text: t('onboarding.buyer.features_description'),
    graphic: graphics.createRequestsGraphic,
    skip: 'buyer_step_two'
  },
  {
    step_position: 1,
    title: t('onboarding.buyer.collaboration_title'),
    privacyTermsSkipClassName: 'privacy-terms-line',
    insetPageClassName: 'wider-info-section',
    text: t('onboarding.buyer.messaging_description'),
    graphic: graphics.collaborateGraphic,
    skip: 'buyer_step_two'
  },
  {
    step_position: 2,
    title: t('onboarding.buyer.manage_suppliers'),
    privacyTermsSkipClassName: 'privacy-terms-line',
    insetPageClassName: 'wider-info-section',
    text: t('onboarding.buyer.find_suppliers'),
    graphic: graphics.suppliersGraphic,
    skip: 'buyer_step_two'
  },
  {
    step_position: 3,
    title: t('onboarding.buyer.run_live_events'),
    privacyTermsSkipClassName: 'privacy-terms-line',
    insetPageClassName: 'wider-info-section',
    text: t('onboarding.buyer.invite_suppliers'),
    graphic: graphics.eventsGraphic,
    skip: 'buyer_step_two'
  },
  {
    step_position: 4,
    title: t('onboarding.buyer.awarding_title'),
    privacyTermsSkipClassName: 'privacy-terms-line',
    insetPageClassName: 'wider-info-section',
    text: t('onboarding.buyer.analytics_description'),
    graphic: graphics.awardGraphic,
    buttonText: t('general.continue'),
    call: 'buyer_step_two',
    skip: 'buyer_step_two'
  }
]);

// eslint-disable-next-line consistent-return
const getAsyncSpendCategories = async () => {
  const result = await getSpendCategories();

  if (result.success) return result.spendCategories;
};

// eslint-disable-next-line consistent-return
const getAsyncUserSpendCategories = async () => {
  const result = await getSpendCategoriesForCurrentUser();

  if (result.success) return result.spendCategories;
};

const getAsyncCategoriesChecked = async () => {
  const spendCatList = await getAsyncSpendCategories();
  const userSpendCatList = await getAsyncUserSpendCategories();

  const categorySpendDisplayList = spendCatList.map(category => {
    const categoryInUserList = userSpendCatList.find(
      userCat => category.name === userCat.name && category.category_type === userCat.category_type
    );

    const catExistsInUser = categoryInUserList !== undefined;

    return {
      id: category.id,
      name: category.name,
      category_type: category.category_type,
      checked: catExistsInUser
    };
  });

  return categorySpendDisplayList;
};

const shouldUpdateCompanyName = user => user.is_self_serve && user.company_name === user.email;

class BuyerOnBoard extends Component {
  constructor (props) {
    super(props);

    const step = props.user.on_boarding || 'intro';

    this.t = props.i18n.t;

    this.state = {
      step,
      progress: false,
      buyer_first_name: props.user.first_name,
      buyer_last_name: props.user.last_name,
      buyer_job_title: props.user.job_title,
      buyer_company_name: shouldUpdateCompanyName(props.user) ? '' : props.user.company_name,
      overviewStep: -1,
      initialLoad: true,
      categorySpendDisplayList: []
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.buildInsetPage = this.buildInsetPage.bind(this);
    this.addFirstLastJobTitle = this.addFirstLastJobTitle.bind(this);
    this.buildSkipButton = this.buildSkipButton.bind(this);
    this.handleNavigation = this.handleNavigation.bind(this);
    this.buildOverviewPage = this.buildOverviewPage.bind(this);
    this.handleSetSpendCategoryChecked = this.handleSetSpendCategoryChecked.bind(this);
    this.handlePersistCategorySelections = this.handlePersistCategorySelections.bind(this);
    this.validateJobTitlePage = this.validateJobTitlePage.bind(this);
  }

  componentDidMount () {
    const { initialLoad } = this.state;

    if (initialLoad) {
      const categorySpendDisplayListCall = getAsyncCategoriesChecked();

      categorySpendDisplayListCall.then(result => {
        this.setState({ categorySpendDisplayList: result, initialLoad: false });
      });
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.user?.id !== this.props.user?.id) {
      this.setState({
        buyer_first_name: this.props.user.first_name,
        buyer_last_name: this.props.user.last_name,
        buyer_job_title: this.props.user.job_title,
        buyer_company_name: shouldUpdateCompanyName(this.props.user) ? '' : this.props.user.company_name
      });
    }
  }

  handleSpecificNavigation (index) {
    this.setState({ overviewStep: index });
  }

  handlePersistCategorySelections () {
    const { categorySpendDisplayList } = this.state;

    updateSpendCategoriesForCurrentUser(categorySpendDisplayList);
  }

  handleSetSpendCategoryChecked (spendCategory) {
    const { categorySpendDisplayList } = this.state;

    const index = categorySpendDisplayList.map(e => e.id).indexOf(spendCategory.id);

    categorySpendDisplayList[index] = { ...spendCategory, checked: !spendCategory.checked };
    this.setState({ categorySpendDisplayList });
  }

  handleSubmit (currentStep, nextStep) {
    if (currentStep === 'intro') {
      const params = {
        first_name: this.state.buyer_first_name,
        last_name: this.state.buyer_last_name,
        job_title: this.state.buyer_job_title
      };

      if (shouldUpdateCompanyName(this.props.user)) params.company_name = this.state.buyer_company_name;
      this.updateUserProfileFields(params, nextStep);
      return;
    }

    if (currentStep === 'buyer_step_one') {
      if (nextStep === 'start_overview') {
        this.handleNavigation('forward');
      } else {
        this.updateOnboardStep(nextStep);
      }
      return;
    }
    if (currentStep === 'buyer_step_two') {
      this.handlePersistCategorySelections();
      this.updateOnboardStep(nextStep);
      return;
    }
    this.updateOnboardStep(nextStep);
    // step two operation, gathers some data?
  }

  handleChange (event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleNavigation (direction) {
    let { overviewStep } = this.state;

    if (direction === 'forward') {
      overviewStep += 1;
    } else {
      overviewStep -= 1;
    }

    if (overviewStep > 4) {
      overviewStep = 4;
    } else if (overviewStep < 0) {
      overviewStep = 0;
    }

    this.setState({ overviewStep });
  }

  updateUserProfileFields (data, nextStep) {
    this.setState({ progress: true });
    updateUserProfileFields(data)
      .then(({ success, errors }) => {
        if (success) {
          this.updateOnboardStep(nextStep);
          return;
        }
        alertMessage(errors[0], 'error', 10);
        this.setState({ progress: false });
      });
  }

  updateOnboardStep (nextStep) {
    this.setState({ progress: true });
    setOnBoardStep(nextStep)
      .then(({ success, errors, step }) => {
        if (!success) {
          alertMessage(errors[0], 'error', 10);
          this.setState({ progress: false });
          return;
        }
        if (step !== 'completed') {
          this.setState({ step, progress: false });
          return;
        }
        if (!this.props.user.is_self_serve) {
          this.props.router.reload();
          return;
        }
        const introRequestId = this.props.user.intro_bid_request_id || '';

        if (!introRequestId) {
          this.props.router.reload();
          return;
        }
        this.props.router.push(`/bid_requests/${introRequestId}/dashboard?show_intercom_tour=true`);
        this.setState({ step, progress: false });
      });
  }

  addFirstLastJobTitle () {
    const firstName = this.state.buyer_first_name || '';
    const lastName = this.state.buyer_last_name || '';
    const jobTitle = this.state.buyer_job_title || '';
    const companyName = this.state.buyer_company_name || '';

    return (
      <div className='first-last-job-form'>
        <section className='name-section'>
          <div className='name-box'>
            <label className='body-text-form-label' htmlFor='buyer_first_name'>
              {this.t('general.first_name')}
              <input
                id='buyer_first_name'
                name='buyer_first_name'
                value={firstName}
                className='input-box-only-underline'
                onChange={this.handleChange}
                type='text'
              />
            </label>
          </div>
          <div className='name-box'>
            <label className='body-text-form-label' htmlFor='buyer_last_name'>
              {this.t('general.last_name')}
              <input
                id='buyer_last_name'
                name='buyer_last_name'
                value={lastName}
                className='input-box-only-underline'
                onChange={this.handleChange}
                type='text'
              />
            </label>
          </div>
        </section>
        <section className='job-title-section'>
          <div className='job-title-box'>
            <label className='body-text-form-label' htmlFor='buyer_job_title'>
              {this.t('general.job_title')}
              <input
                id='buyer_job_title'
                name='buyer_job_title'
                value={jobTitle}
                className='input-box-only-underline'
                onChange={this.handleChange}
                type='text'
              />
            </label>
          </div>

          {shouldUpdateCompanyName(this.props.user) && (
            <div className='job-title-box'>
              <label className='body-text-form-label' htmlFor='buyer_company_name'>
                {this.t('general.company_name')}
                <input
                  id='buyer_company_name'
                  name='buyer_company_name'
                  value={companyName}
                  className='input-box-only-underline'
                  onChange={this.handleChange}
                  type='text'
                />
              </label>
            </div>
          )}
        </section>
      </div>
    );
  }

  buildSkipButton (skipToStep) {
    return (
      <span className='skip-span'>
        <Button
          disabled={this.state.progress}
          loading={this.state.progress}
          onClick={() => this.handleSubmit(this.state.step, skipToStep)}
          type="text"
          size="small"
        >
          {this.t('general.skip')}
          <RightOutlined />
        </Button>
      </span>
    );
  }

  buildPrivacyTermsLine (stepDetails) {
    return (
      <div className={stepDetails.privacyTermsSkipClassName}>
        <span>
          <a
            className='Privacy-Policy-Ter'
            href='https://arkestro.com/privacy-policy'
            rel='noopener noreferrer'
            target='_blank'
          >
            {this.t('general.privacy_policy')}
          </a>
          &nbsp;|&nbsp;
          <a
            className='Privacy-Policy-Ter'
            href='https://arkestro.com/terms-of-use'
            rel='noopener noreferrer'
            target='_blank'
          >
            {this.t('general.terms_of_use')}
          </a>
        </span>
        {stepDetails.skip && this.buildSkipButton(stepDetails.skip)}
      </div>
    );
  }

  addSpendButtons () {
    const { categorySpendDisplayList } = this.state;

    const directSpend = categorySpendDisplayList.find(c => c.category_type === DIRECT_SPEND && c.name === 'None');
    const indirectSpend = categorySpendDisplayList.find(c => c.category_type === INDIRECT_SPEND && c.name === 'None');
    const directSpendSelected = directSpend && directSpend.checked;
    const indirectSpendSelected = indirectSpend && indirectSpend.checked;

    const other = categorySpendDisplayList.find(c => c.category_type === 'Other');
    const otherSelected = other && other.checked;

    return (
      <div style={SPEND_CATEGORY_LIST_STYLE}>
        <div className='dropdown-button-div'>
          <Button
            block
            onClick={() => this.handleSetSpendCategoryChecked(directSpend)}
            ghost={!directSpendSelected}
            type="secondary"
          >
            {this.t('general.direct_spend')}
          </Button>
        </div>
        <div className='dropdown-button-div'>
          <Button
            block
            onClick={() => this.handleSetSpendCategoryChecked(indirectSpend)}
            ghost={!indirectSpendSelected}
            type="secondary"
          >
            {this.t('general.indirect_spend')}
          </Button>
        </div>
        <div className='dropdown-button-div'>
          <Button
            block
            onClick={() => this.handleSetSpendCategoryChecked(other)}
            ghost={!otherSelected}
            type="secondary"
          >
            {this.t('general.other')}
          </Button>
        </div>

        <SpendTypesTooltipLine />
      </div>
    );
  }

  validateJobTitlePage () {
    const { buyer_first_name, buyer_last_name, buyer_job_title, buyer_company_name } = this.state;

    let allFieldsAreFilledOut =
      buyer_first_name &&
      buyer_first_name.length > 0 &&
      buyer_last_name &&
      buyer_last_name.length > 0 &&
      buyer_job_title &&
      buyer_job_title.length > 0;

    if (shouldUpdateCompanyName(this.props.user))
      allFieldsAreFilledOut = allFieldsAreFilledOut && buyer_company_name && buyer_company_name.length > 0;

    return allFieldsAreFilledOut;
  }

  buildInsetPage (stepDetails) {
    const shouldDisableSubmit = stepDetails.step === 'intro' && !this.validateJobTitlePage();

    return (
      <div className='flex-vertical-card'>
        <div className='title-text'>
          {stepDetails.title}&nbsp;{stepDetails.graphic}
        </div>
        <div className='body-text'>
          <div className={stepDetails.customTextClass ? stepDetails.customTextClass : ''}>{stepDetails.text}</div>
          {stepDetails.step === 'intro' && this.addFirstLastJobTitle()}
          {stepDetails.step === 'buyer_step_two' && this.addSpendButtons()}
          <div className='continue-button-div'>
            <Button
              disabled={this.state.progress || shouldDisableSubmit}
              loading={this.state.progress}
              onClick={() => this.handleSubmit(stepDetails.step, stepDetails.call)}
              type="secondary"
            >
              {stepDetails.buttonText}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  generateCircleButtons (numOfPages, minusIndex) {
    const pages = [];

    for (let index = 0; index < numOfPages; index += 1) {
      const graphic = index === minusIndex ? graphics.rectangle : graphics.oval;

      pages.push(
        <Button
          key={index}
          type='link'
          onClick={() => this.handleSpecificNavigation(index)}
        >
          {graphic}
        </Button>
      );
    }
    return pages;
  }

  buildOverviewPage (overviewStepDetails) {
    const endPageIndex = overviewSteps(this.t).length - 1;

    return (
      <div className='flex-horizontal-card'>
        <div className='text-column'>
          <div className='text-box'>
            <div className='title-text'>{overviewStepDetails.title}</div>
            <div className='body-text'>{overviewStepDetails.text}</div>
          </div>
          <div className='nav-box'>
            <Button
              type='link'
              disabled={this.state.progress || overviewStepDetails.step_position === 0}
              onClick={() => this.handleNavigation('backward')}
              icon={<LeftCircleOutlined />}
            />
            <span className='nav-circle-span'>
              {this.generateCircleButtons(overviewSteps(this.t).length, overviewStepDetails.step_position)}
            </span>
            <Button
              type='link'
              disabled={this.state.progress || overviewStepDetails.step_position === endPageIndex}
              onClick={() => this.handleNavigation('forward')}
              icon={<RightCircleOutlined />}
            />
          </div>
        </div>
        <div className='graphic-column'>
          <div className='graphic-div-area'>{overviewStepDetails.graphic}</div>
          {overviewStepDetails.step_position === endPageIndex && (
            <div className='continue-button-div'>
              <Button
                disabled={this.state.progress}
                loading={this.state.progress}
                onClick={() => this.handleSubmit(this.state.step, overviewStepDetails.call)}
                type="secondary"
              >
                {overviewStepDetails.buttonText}
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }

  render () {
    const stepIndex = steps(this.t).map(st => st.step).indexOf(this.state.step);
    const [stepDetails, overview] =
      this.state.step === 'buyer_step_one' && this.state.overviewStep >= 0
        ? [overviewSteps(this.t)[this.state.overviewStep], true]
        : [steps(this.t)[stepIndex], false];
    const insetPage = !overview;

    return (
      <StrictMode>
        <Card className='buyer-onboard'>
          <div className={stepDetails.insetPageClassName}>
            <div className='header'>{graphics.logo}</div>
            {insetPage && this.buildInsetPage(stepDetails)}
            {overview && this.buildOverviewPage(stepDetails)}
            {this.buildPrivacyTermsLine(stepDetails)}
          </div>
        </Card>
      </StrictMode>
    );
  }
}

const exportedComponent = props => <UserContext.Consumer>
  {user => <BuyerOnBoard {...props} user={user} />}
</UserContext.Consumer>;

export default withRouter(withTranslation(exportedComponent, 'common'));
