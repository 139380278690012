import React, { useContext } from 'react';
import { Layout, Affix } from 'antd-v3';
import { UserContext } from '@components/providers/UserProvider/UserProvider';
import LogoImage from 'components/LogoImage/LogoImage';
import DeeplinkLogin from 'components/Login/DeeplinkLoginDisplay/DeeplinkLogin';
import SuperAdminBar from 'components/layout/SuperAdminBar/SuperAdminBar';
import { LayoutContext } from 'components/providers/LayoutProvider/LayoutProvider';
import SettingsDisplay from './SettingsDisplay/SettingsDisplay';
import Collapser from './Collapser/Collapser';
import style from './Header.module.scss';

const HEADER_STYLE = {
  height: 90,
  background: '#ffffff',
  padding: '0 10px 0 0',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  borderBottom: '1px solid #e5e9ed'
};

const Header = () => {
  const user = useContext(UserContext);
  const isDeeplink = user.is_deeplink;
  const { collapsed, toggleSideMenu } = useContext(LayoutContext);
  
  return (
 
    <Affix offsetTop={0}>
      <div>
        <SuperAdminBar />
        <Layout.Header style={HEADER_STYLE} id='nav-header' className={style.container}>
          <div className={style.leftColumn}>
            <Collapser toggleSideMenu={toggleSideMenu} />
            <LogoImage
              imageStyle={{ maxWidth: 150, maxHeight: 65 }}
              useDark
              useMyCompanyLogo
              fallbackToBidops={collapsed}
            />
          </div>
          {isDeeplink ? <DeeplinkLogin /> : <SettingsDisplay />}
        </Layout.Header>
      </div>
    </Affix>
   
  );};

export default Header;
