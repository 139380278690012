import { Dispatch, SetStateAction, useContext } from 'react';
import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';

import { fullName } from '@utils/names';
import { GeneralContext } from '@components/providers/GeneralProvider/GeneralProvider';
import NotificationsListGroup from
  '@components/layout/Header/Notifications/NotificationsListGroup/NotificationsListGroup';
import {
  BidAcceptanceInviteNotification,
  Notification,
  SupplierDatabaseInviteNotification
} from '@components/layout/Header/Notifications/types';

import { mapCompanyInviteToNotification, mapRequestInviteToNotification } from './utils';

type Props = {
  setAllList: Dispatch<SetStateAction<Notification[]>>;
  allList: Notification[];
};

const NotificationsGeneralListGroup = ({ allList, setAllList }: Props): JSX.Element | null => {
  const { t } = useTranslation('common');
  const { companyInvites = [], bidAcceptances = [] } = useContext(GeneralContext);

  // Get the notifications that do not have request information attached to be in the General section
  const generalList: Notification[] = allList
    .filter(notify => notify.actions_object && notify.additional_info && !notify.additional_info.bid_request_id);

  const companyInviteMessage = (invite: SupplierDatabaseInviteNotification) => {
    const buyerName = fullName(invite.buyer);

    const key = buyerName !== ''
      ? 'common:notifications.supplier_database_invitation_message_from_buyer'
      : 'common:notifications.supplier_database_invitation_message';

    const data = buyerName !== ''
      ? { buyer_name: buyerName, company_name: invite.company.name }
      : { name: invite.company.name };

    return (
      // eslint-disable-next-line react/jsx-key
      <Trans i18nKey={key} components={[<strong />]} values={data} />
    );
  };

  const companyInvitesAsNotifications: Notification[] = companyInvites.map(
    (invite: SupplierDatabaseInviteNotification) => {
      const initialValues = mapCompanyInviteToNotification(t, invite);

      return {
        ...initialValues,
        description: companyInviteMessage(invite),
      };
    }
  );
  const bidAcceptancesAsNotifications: Notification[] = bidAcceptances.map(
    (invite: BidAcceptanceInviteNotification) => {
      const initialValues = mapRequestInviteToNotification(t, invite);

      return {
        ...initialValues,
        description:(
          <Trans
            i18nKey="common:notifications.request_invitation_message"
            // eslint-disable-next-line react/jsx-key
            components={[<strong />]}
            values={{
              buyer_name: invite.buyer_clean_name,
              request_name: invite.bid_request.name,
              company_name: invite.company.name
            }}
          />
        ),
      };
    }
  );

  generalList.push(...companyInvitesAsNotifications);
  generalList.push(...bidAcceptancesAsNotifications);

  if (generalList.length === 0) return null;

  return (
    <NotificationsListGroup
      title={t('notifications.general')}
      list={generalList}
      setAllList={setAllList}
      allList={allList}
    />
  );
};

export default NotificationsGeneralListGroup;
