import React from 'react';
import Loading from 'components/shared/Loading/Loading';

const EmailCheckDisplay = email => (
  <>
    <div className='title'>Verifying your email</div>
    <div className='subtitle' style={{ marginTop: 30 }}>
    Checking if your email matches an existing Supplier Organization on Arkestro.
    </div>
    <Loading text='Verifying...' />
  </>
);

export default EmailCheckDisplay;
