import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { Checkbox } from 'antd-v3';
import { Button, Col, Row, Tooltip } from '@arkestro/arkestro-design-system';
import { InfoCircleFilled } from '@ant-design/icons';
import { UserContext } from '@providers/UserProvider/UserProvider';
import useTranslation from 'next-translate/useTranslation';
import { attachTrackingAnalytics } from '@services/SegmentService';
import { emailParts } from 'utils/email';
import { isValidSupplierOrgName } from 'utils/names';
import {
  VENDOR_CREATE_ORGANIZATION_VIEWED,
  VENDOR_CREATED_ORGANIZATION_PROFILE,
  OPTED_IN_TO_PUBLIC_DB
} from 'utils/analytics_constants';
import Footer from 'components/on_board/Footer/Footer';
import InputField from 'components/shared/forms/InputField/InputField';
import { updateVendorProfile } from 'api/VendorProfileDbApi';
import { updateVendorOrganization } from 'api/VendorDBApi';
import { alertMessage } from 'utils/common';

// TODO: Tech Debt - Is this still used??

const CreateNewOrgFields = ({ currentStepName, updateOnboardStep, updateOrgId, progress, isFreeEmail }) => {
  const { t } = useTranslation('common');
  const currentUser= useContext(UserContext);

  useEffect(() => attachTrackingAnalytics(VENDOR_CREATE_ORGANIZATION_VIEWED), []);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [website, setWebsite] = useState('');
  const [publicListed, setPublicListed] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [didSuccessfullySubmit, setDidSuccessfullySubmit] = useState(false);
  const isFormValid = isValidSupplierOrgName(name) && description.length > 0;

  const handleChange = event => {
    let setFn;

    if (event.target.name === 'publicListed') {
      setPublicListed(event.target.checked);
      return;
    }

    switch (event.target.name) {
      case 'website':
        setFn = setWebsite; break;
      case 'description':
        setFn = setDescription; break;
      default:
        setFn = setName; break;
    }

    setFn(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (!isFormValid || isSubmitting) return;

    const parts = isFreeEmail ? {} : emailParts(currentUser.email);
    const { domain, tld } = parts;
    const addDomain = (domain && tld)
      ? {
        name: domain,
        url: domain.concat('.').concat(tld)
      }
      : null;

    const data = { name, description, website, publicListed };

    if (updateOrgId) data.vendorOrgId = updateOrgId;
    if (addDomain) data.domains = [ addDomain ];

    setIsSubmitting(true);

    // Create the new vendor organization using current currentUser. email
    updateVendorOrganization(data)
      .then(response => {
        if (!response.success) {
          alertMessage(response.errors[0], 'error', 10);
          setIsSubmitting(false);
          return;
        }

        setDidSuccessfullySubmit(true);
        attachTrackingAnalytics(VENDOR_CREATED_ORGANIZATION_PROFILE, { [OPTED_IN_TO_PUBLIC_DB]: publicListed });

        if (updateOrgId) { // Should be associated already
          updateOnboardStep(currentStepName); // updates onboarding state, which advances to next step
        }
        else {
          // We could update the endpoint to not return root and use matching attributes and not shorthand
          const vendorOrgId = response.vendor_organization.id;

          // link the newly created vendor org to the vendor profile
          updateVendorProfile({ email: currentUser.email, vendorOrgId })
            .then(profileResponse => {
              if (!profileResponse.success) {
                setIsSubmitting(false);
                return;
              }

              updateOnboardStep(currentStepName); // updates onboarding state, which advances to next step
            });
        }
      });
  };

  return (
    <>
      <div className='title'>{t('onboarding.supplier.provide_information')}</div>

      <form onSubmit={handleSubmit}>
        <section style={{ marginTop: 30 }}>
          <Row gutter={16}>
            <Col sm={24} style={{ marginBottom: 15 }}>
              <InputField
                name='name'
                labelText={t('onboarding.supplier.organization_name')}
                tooltipText={t('tooltip.new_org.organization_name_visible')}
                value={name}
                onChange={handleChange} required
              />
            </Col>
            <Col sm={24}>
              <InputField
                name='description'
                labelText={t('onboarding.supplier.organization_description')}
                tooltipText={t('tooltip.new_org.tell_prospective')}
                value={description}
                onChange={handleChange}
                required
                textArea
              />
            </Col>
          </Row>
        </section>
        <section style={{ marginTop: 30 }}>
          <Row gutter={16}>
            <Col sm={24}>
              <InputField
                name='website'
                labelText={t('onboarding.supplier.organization_website')}
                value={website}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </section>
        <section style={{ marginTop: 30 }}>
          <Row gutter={16}>
            <Col sm={24}>
              <Checkbox onChange={handleChange} name='publicListed' checked={publicListed}>
                {t('onboarding.supplier.add_organization_to_directory')}
                <Tooltip
                  placement='bottom'
                  title={t('tooltip.new_org.public_listed')}
                  overlayStyle={{ minWidth: 300 }}
                >
                  <InfoCircleFilled style={{ fontSize: 12, marginLeft: 10 }} />
                </Tooltip>
              </Checkbox>
            </Col>
          </Row>
        </section>
        <Row style={{ marginTop: 30 }} justify='center'>
          <Col xs={20} sm={12} lg={9} xl={10}>
            <Button
              htmlType='submit'
              disabled={!isFormValid || isSubmitting}
              loading={progress}
              type="primary"
              block
            >
              {t('general.continue')}
            </Button>
          </Col>
        </Row>
      </form>
      <Footer />
      {didSuccessfullySubmit && publicListed && (
        <img
          height='1'
          width='1'
          style={{ display: 'none' }}
          alt=''
          src='https://px.ads.linkedin.com/collect/?pid=991332&fmt=gif'
        />
      )}
    </>
  );
};

CreateNewOrgFields.propTypes = {
  currentStepName: PropTypes.string.isRequired,
  updateOnboardStep: PropTypes.func.isRequired,
  updateOrgId: PropTypes.number,
  progress: PropTypes.bool.isRequired,
  isFreeEmail: PropTypes.bool
};

CreateNewOrgFields.defaultProps = {
  updateOrgId: undefined,
  isFreeEmail: true
};

export default CreateNewOrgFields;
