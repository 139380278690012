class HTMLScriptService {
  static appendAndConnect = (src, connectFn) => {
    if (typeof window === 'undefined') return;

    const script = document.createElement('script');

    let type = 'text/javascript';

    if (src.includes('.css') || src.includes('.scss')) type = 'text/css';

    script.src = src;
    script.type = type;
    // script.crossOrigin = 'anonymous';

    // If we don't need to wait for any script to load, stop here.
    if (!connectFn) {
      document.getElementsByTagName('head')[0].appendChild(script);
      return;
    }

    // Make sure a script is ready before initializing a library
    if (script.readyState) {
      // only required for IE <9
      script.onreadystatechange = () => {
        if (script.readyState !== 'loaded' || script.readyState !== 'complete') return;

        script.onreadystatechange = null;
        connectFn();
      };
    } else {
      // Other browsers
      script.onload = () => {
        connectFn();
      };
    }

    document.getElementsByTagName('head')[0].appendChild(script);
  }
}

export default HTMLScriptService;
