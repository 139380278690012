import HTMLScriptService from './HTMLScriptService';

class NPSService {
  static initialize = () => {
    if (`${process.env.NEXT_PUBLIC_USE_NPS_SERVICE}`.toLowerCase() !== 'true') return;

    // JavaScript Snippet
    HTMLScriptService.appendAndConnect(
      'https://static.asknice.ly/dist/standalone/asknicely-in-app-conversation.js'
    );

    // Stylesheet
    HTMLScriptService.appendAndConnect(
      'https://static.asknice.ly/dist/standalone/asknicely-in-app-conversation.css',
    );

  }
}

export default NPSService;
