import Badge from '@material-ui/core/Badge';
import PropTypes from 'prop-types';
import { MailFilled } from '@ant-design/icons';
import useTranslation from 'next-translate/useTranslation';

const renderIcon = text => (
  <>
    <MailFilled />
    <span>{text}</span>
  </>
);

const CommCenterIcon = ({ unreadCount }) => {
  const { t } = useTranslation('common');

  if (!unreadCount || unreadCount === 0) return renderIcon(t('general.messages'));

  return (
    <Badge
      badgeContent={unreadCount}
      color='secondary'
      className='badge-count'
      style={{ display: 'inline' }}
      classes={{ badge: 'unreadCountBadge' }}
    >
      {renderIcon(t('general.messages'))}
    </Badge>
  );
};

CommCenterIcon.propTypes = {
  unreadCount: PropTypes.number
};

CommCenterIcon.defaultProps = {
  unreadCount: null
};

export default CommCenterIcon;
